import React from "react";
import { Link, GatsbyLinkProps } from "gatsby";

import "./style.css";

interface GradientButtonProps extends GatsbyLinkProps<any> {
  external?: boolean;
}

export default function GradientButton({ children, className, to, role, external }: GradientButtonProps) {
  if (external) {
    return (
      <a 
        href={to}
        className={`btn border-0 text-white text-decoration-none text-uppercase btn__gradient py-2 ${className}`}
        role={role}
        rel='noopener noreferer'
        target='_blank'>
        {children}
      </a>
    );
  } else {

    return (
      <Link to={to || '#'} className={`btn border-0 text-white text-decoration-none text-uppercase btn__gradient py-2 ${className}`} role={role}>
        {children}
      </Link>
    );
  }
}