const fenanPro = {
  section1: {
    mainTitle: `Nous faisons bien plus que de la simple distribution digitale`,
    description: `Sonneries téléphoniques à partir de vos chansons, mastering de vos titres, création de site, obtention de votre profil artiste, configuration d’une chaine VEVO, promotion de votre dernier titre… Dropzik vous aide à lancer votre carrière musicale.`,
  },
  section2: {
    cards: [
      {
        title: `Fenan Pro`,
        price: `Bientôt disponible`,
        link: `https://app.dropzik.com/services/subscriptions`,
        linkLabel: `Voir plus`
      },
      {
        title: `Mastering`,
        price: `Bientôt disponible`
      },
      {
        title: `Fabrication de Cd et vinyle`,
        price: `Bientôt disponible`
      },
      {
        title: `Publicité et gestion des réseaux sociaux`,
        price: `Bientôt disponible`
      },
      {
        title: `Chaine vevo`,
        price: `Bientôt disponible`
      },
      {
        title: `Smart Links`,
        price: `Bientôt disponible`
      },
      {
        title: `Pochette d’album`,
        price: `Bientôt disponible`
      },
      {
        title: `Autorisation Cover`,
        price: `Bientôt disponible`
      },
    ]
  }
};

export default fenanPro;