import React, { ReactNode } from 'react';
import GradientButton from '../gradient-button';

import './style.css';

interface CardProps {
  link?: string;
  Image: ReactNode;
  title: string;
  content: ReactNode;
  linkLabel?: string;
  className?: string;
  imageContainerClassName?: string;
  titleClassName?: string;
  contentClassName?: string;
  linkClassName?: string;
  cardBodyClassName?: string;
  externalLink?: boolean;
}

/**
 * Card component
 * @name Card
 * @description display a custom card
 */
export default function Card({ Image, link, title, content, cardBodyClassName = '', linkLabel, externalLink = false, linkClassName = '', className = '', imageContainerClassName: imageClassName = '', titleClassName = '', contentClassName = '' }: CardProps) {
  return (
    <div className={`card card__block flex_1 border-0 rounded-0 d-flex z-index_1 ${className}`}>
      <div className={`card__image-block position-relative ${imageClassName}`}>
        {
          Image
        }
      </div>
      <div className={`card-content card__content d-flex flex-column flex_1 ${cardBodyClassName}`}>
        <h3 className={`card-title mb-0 d-bold card__title text-uppercase text-center ${titleClassName}`}>
          {title}
        </h3>
        <p className={`card__description flex_1 ${contentClassName}`}>{content}</p>
        {
          link && (

            <div className="d-flex justify-content-center">
              <GradientButton
                to={link}
                external={externalLink}
                className={`card__btn d-bold ${linkClassName}`}
              >
                {linkLabel}
              </GradientButton>
            </div>
          )
        }
      </div>
    </div>

  )
}
