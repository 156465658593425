import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import fenanPro from '../config/contents/fenan-pro';
import Image from "../components/image";
import Card from "../components/card";

import '../assets/css/fenan-pro.css';

const FenanProPage: React.FC<PageProps<any>> = ({ data }) => {
  const { heroBg, site, productsImages } = data;
  const { edges: productsImagesNode } = productsImages;
  const { title, path, description } = site?.siteMetadata?.pages?.fenanPro;

  return (
    <Layout>
      <SEO title={title} description={description} path={path} image={heroBg?.childImageSharp.fluid.src} />

      <div className="fenan-pro__block">

        <section className="d-block position-relative">

          <Image
            className='image__full-width h-100 w-100 position-absolute'
            src={heroBg?.childImageSharp?.fluid}
          />

          <div className="container position-relative">
            <div className="container__row row justify-content-start justify-content-md-end">
              <div className='hero__content d-flex flex-column justify-content-center text-white'>
                <h1 className="title mt-0 mb-4 pr-2">
                  Nous faisons <strong className="d-bold">bien plus</strong> que de la simple{` `}
                  <strong className="d-bold">distribution digitale</strong>
                </h1>
                <p className='font-weight-light fsz-20 m-0 p-0 pr-0 pr-md-2'>{fenanPro.section1.description}</p>
              </div>
            </div>
          </div>

        </section>

        <section className="d-block py-5 bg-grey">

          <div className="container py-5">
            <div className="container__row row pb-0 pb-md-5">
              <div className="d-flex flex-wrap products__block pb-0 pb-md-5 mb-0 mb-md-5">

                {
                  fenanPro.section2.cards.map((item, i) => (
                    <Card
                      key={item.title}
                      Image={
                        <Image
                          src={productsImagesNode[i]?.node?.childImageSharp?.fluid}
                          alt={item.title} />
                      }
                      className='flex_auto'
                      imageContainerClassName='text-center'
                      titleClassName='fsz-20'
                      title={item.title}
                      link={item?.link}
                      linkLabel={item?.linkLabel}
                      content={``}
                      contentClassName='fsz-16 m-0 px-0'
                      cardBodyClassName='px-0'
                      externalLink
                    />
                  ))
                }

              </div>
            </div>
          </div>

        </section>

      </div>

    </Layout>
  );
}

export const query = graphql`
  query fenanProQuery {
    site {
      siteMetadata {
        lang
        pages {
          fenanPro {
            title
            path
            description
          }
        }
      }
    }
    heroBg: file(relativePath: { eq: "fenan-pro/hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    productsImages: allFile(
      filter: {
        extension: {
          regex: "/(jpg)|(jpeg)|(png)/"
        }, 
        relativeDirectory: {
          eq: "fenan-pro/products"
        }
      }
      sort: {
        fields: [name],
        order: ASC
      }
      ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 235) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default FenanProPage;
